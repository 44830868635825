.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.tenant-separator {
  border-bottom: 3px solid #f0f0f0;
  margin-top: 10px!important;
  margin-bottom: 10px!important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MuiTableCell-root .MuiButtonBase-root {
  background: none!important;
}
.swal2-container {
  z-index: 20000 !important;
}



.box {
  border: 1px solid #ddd;
}
.box .steps {
  border-bottom: 1px solid #ddd;
}
.box .steps ul {
  overflow: hidden;
}
.box .steps ul li div {
  color: #999;
  padding: 10px 0 15px 15px;
  position: relative;
  background: #f5f5f5;
  width: 185px;
}
.box .steps ul li div span {
  font-size: 13px;
}
.box .steps ul li:first-child div {
  width: 185px;
  padding-left: 10px;
}
.box .steps ul li div::before {
  content: " ";
  border-top: 50px solid transparent;
  border-bottom: 50px solid transparent;
  border-left: 30px solid #ddd;
  position: absolute;
  top: 50%;
  margin-top: -50px;
  left: 100%;
  z-index: 1;
  margin-left: 1px;
}
.box .steps ul li div::after {
  content: " ";
  border-top: 50px solid transparent;
  border-bottom: 50px solid transparent;
  border-left: 30px solid #f5f5f5;
  position: absolute;
  top: 50%;
  margin-top: -50px;
  left: 100%;
  z-index: 2;
}
.box .steps ul li.done div {
  border-color: #6fbb60 !important;
  color: #fff !important;
  background: #6fbb60 !important;
}
.box .steps ul li.done div::after {
  border-left: 30px solid #6fbb60;
  margin-left: -1px;
}
.box .steps ul li.active div {
  border-color: #757575 !important;
  color: #fff !important;
  background: #757575 !important;
}
.box .steps ul li.active div::after {
  border-left: 30px solid #757575;
  margin-left: -1px;
}

.box .step-component {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.box .btn-component {
  display: flex;
  width: 100%;
  padding-top: 15px;
  justify-content: space-between;
}
.box .contrat-step-error,
.box .owner-step-error{
  padding: 20px 16px 0;
}

.step-one-next-btn {
  float: left;
}
.add-tenant .Mui-disabled svg {
  color: lightgray;
}

@media screen and (max-width: 767px) {
  .box .steps ul li div::before {
    content: " ";
    border-top: 40px solid transparent;
    border-bottom: 40px solid transparent;
    position: absolute;
    top: 50%;
    margin-top: -40px;
    left: 100%;
    z-index: 1;
    margin-left: 1px;
  }
  .box .steps ul li div::after {
    content: " ";
    border-top: 40px solid transparent;
    border-bottom: 40px solid transparent;
    position: absolute;
    top: 50%;
    margin-top: -40px;
    left: 100%;
    z-index: 2;
  }
}
